import React from 'react';
import { Link } from 'react-scroll';
import classes from './HeroSection.module.css';

const HeroSection = () => {
  return (
    <section className={classes.sectionHero}>
      <h1 className={classes.headingPrimary}>Hoofie and the Bad Habits</h1>
      <p className={classes.description}>Jazz, blues and a groovy time!</p>
      {/* <a href='#' className='btn btn--full margin-right-sm'>
        Subscribe
      </a> */}
      <button className='btn btn--outline'>
        <Link to='section-origin' smooth={true} duration={500}>
          Learn more &darr;
        </Link>
      </button>
      <div id='sectionEnd'></div>
    </section>
  );
};

export default HeroSection;
