export const photos = [
  {
    src: '/images/012.jpeg',
    width: 4,
    height: 3,
  },
  {
    src: '/images/001.jpg',
    width: 4,
    height: 3,
  },
  {
    src: '/images/002.jpg',
    width: 1,
    height: 1,
  },
  {
    src: '/images/003.jpg',
    width: 3,
    height: 4,
  },
  {
    src: '/images/004.jpg',
    width: 3,
    height: 4,
  },
  {
    src: '/images/013.jpeg',
    width: 4,
    height: 3,
  },
  {
    src: '/images/005.jpg',
    width: 3,
    height: 4,
  },
  {
    src: '/images/006.jpg',
    width: 4,
    height: 3,
  },
  {
    src: '/images/014.jpeg',
    width: 3,
    height: 4,
  },
  {
    src: '/images/007.jpg',
    width: 3,
    height: 4,
  },
  {
    src: '/images/008.jpg',
    width: 4,
    height: 3,
  },
  {
    src: '/images/009.jpg',
    width: 4,
    height: 3,
  },
  {
    src: '/images/010.jpg',
    width: 3,
    height: 4,
  },
  {
    src: '/images/015.jpeg',
    width: 3,
    height: 4,
  },
  {
    src: '/images/011.jpg',
    width: 4,
    height: 3,
  },
  {
    src: '/images/016.jpeg',
    width: 3,
    height: 4,
  },
  {
    src: '/images/017.jpeg',
    width: 3,
    height: 4,
  },
  // {
  //   src: 'https://source.unsplash.com/u9cG4cuJ6bU/4927x1000',
  //   width: 4927,
  //   height: 1000,
  // },
  // {
  //   src: 'https://source.unsplash.com/qGQNmBE7mYw/800x599',
  //   width: 4,
  //   height: 3,
  // },
  // {
  //   src: 'https://source.unsplash.com/NuO6iTBkHxE/800x599',
  //   width: 4,
  //   height: 3,
  // },
  // {
  //   src: 'https://source.unsplash.com/pF1ug8ysTtY/600x400',
  //   width: 4,
  //   height: 3,
  // },
  // {
  //   src: 'https://source.unsplash.com/A-fubu9QJxE/800x533',
  //   width: 4,
  //   height: 3,
  // },
  // {
  //   src: 'https://source.unsplash.com/5P91SF0zNsI/740x494',
  //   width: 4,
  //   height: 3,
  // },
];
