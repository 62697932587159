import React from 'react';
import Track from './Track';
import { tracks } from './tracks';
import classes from './TrackList.module.css';

const TrackList = () => {
  return (
    <ul className={classes.list}>
      {tracks.map(track => (
        <Track key={track.id} id={track.id} title={track.title} url={track.url} />
      ))}
    </ul>
  );
};

export default TrackList;
