import React from 'react';
import Gig from './Gig';
import { gigs } from './gigs';
import classes from './GigList.module.css';

function GigList() {
  return (
    <ul className={classes.list}>
      {gigs.map(gig => (
        <Gig
          key={gig.id}
          id={gig.id}
          image={gig.src}
          date={gig.date}
          venue={gig.venue}
          address={gig.address}
          description={gig.description}
        />
      ))}
    </ul>
  );
}

export default GigList;
