import React from 'react';
import Video from './Video';
import { videos } from './videos';
import classes from './VideoList.module.css';

const VideoList = () => {
  return (
    <ul className={classes.list}>
      {videos.map(video => (
        <Video key={video.id} id={video.id} title={video.title} url={video.url} />
      ))}
    </ul>
  );
};

export default VideoList;
