import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { IoCloseOutline, IoMenuOutline } from 'react-icons/io5';
import classes from './MainHeader.module.css';
import logo from '../assets/hbh-logo-black.png';

const MainHeader = props => {
  const [navOpen, setNavOpen] = useState(false);

  const toggleNavOpen = () => {
    setNavOpen(!navOpen);
  };

  return (
    <header className={classes.header}>
      <Link to='/'>
        <img className={classes.logo} src={logo} alt='Hoofie and the Bad Habits logo' />
      </Link>

      <nav className={navOpen ? classes.mainNavOpen : classes.mainNav}>
        <ul className={classes.mainNavList} onClick={toggleNavOpen}>
          <li>
            <NavLink to='/' className='mainHeader'>
              Origin
            </NavLink>
          </li>
          <li>
            <NavLink to='/about' className={navData => (navData.isActive ? 'mainHeaderActive' : 'mainHeader')}>
              About
            </NavLink>
          </li>
          <li>
            <NavLink to='/gigs' className={navData => (navData.isActive ? 'mainHeaderActive' : 'mainHeader')}>
              Gigs
            </NavLink>
          </li>
          <li>
            <NavLink to='/photos' className={navData => (navData.isActive ? 'mainHeaderActive' : 'mainHeader')}>
              Photos
            </NavLink>
          </li>
          <li>
            <NavLink to='/videos' className={navData => (navData.isActive ? 'mainHeaderActive' : 'mainHeader')}>
              Videos
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/contact'
              className={navData => (navData.isActive ? 'mainHeaderContactActive' : 'mainHeaderContact')}
            >
              Contact
            </NavLink>
          </li>
        </ul>
      </nav>

      <button className={classes.btnMobileNav} onClick={toggleNavOpen}>
        {!navOpen && <IoMenuOutline className={classes.iconMobileNav} />}
        {navOpen && <IoCloseOutline className={classes.iconMobileNav} />}
      </button>
    </header>
  );
};

export default MainHeader;
