import React from 'react';
import PhotoList from '../components/photos/PhotoList';

const Photos = () => {
  return (
    <section className='container'>
      <h1 className='center-text heading-primary'>Photos</h1>
      <PhotoList />
    </section>
  );
};

export default Photos;
