import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './Layout/Layout';
import About from './pages/About';
import Contact from './pages/Contact';
import Gigs from './pages/Gigs';
import HomePage from './pages/HomePage';
import NotFound from './pages/NotFound';
import Photos from './pages/Photos';
import Tracks from './pages/Tracks';
import Videos from './pages/Videos';

function App() {
  return (
    <Layout>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='*' element={<NotFound />} />
        <Route path='about' element={<About />} />
        <Route path='contact' element={<Contact />} />
        <Route path='gigs' element={<Gigs />} />
        <Route path='photos' element={<Photos />} />
        <Route path='tracks' element={<Tracks />} />
        <Route path='videos' element={<Videos />} />
      </Routes>
    </Layout>
  );
}

export default App;
