import React from 'react';
import ReactPlayer from 'react-player';
import classes from './HomePageContent.module.css';

const HomePageContent = () => {
  return (
    <section className={classes.section} id='section-origin'>
      <div class='center-text'>
        <ReactPlayer url='https://youtu.be/jlgtlG8rbBw' />
      </div>
      <div className='container grid-text'>
        <h2 className={classes.headingSecondary}>An Origin Story</h2>
        <p>
          In the years to come, children that may not even be conceived yet, will sit at their grandfather’s knee and
          ask the plaintive question,
        </p>
        <p>
          <span className={classes.originTextEmphasis}>“</span> What did you do during the pandemic?
          <span className={classes.originTextEmphasis}>”</span>
        </p>
        <p>
          True, some people will have heroic stories of public service, long hours treating patients in the ICU, double
          shifts on an emergency ambulance or home schooling rowdy children. But, there will be only a few, rare people
          who will say - well, little Johnny, I started a band. Little Johnny will look up, with a face full of
          confusion, and ask how was that possible, I thought you were in lockdown?
        </p>
        <p>
          <span className={classes.originTextEmphasis}>“</span> Well{' '}
          <span className={classes.originTextEmphasis}>”</span>, says the Grandfather,{' '}
          <span className={classes.originTextEmphasis}>“</span> now there’s a story...
          <span className={classes.originTextEmphasis}>”</span>
        </p>
        <p>
          And that was how Hoofie started finding his Bad Habits, and we’re not talking about biting his nails and
          leaving the lights on when he leaves a room. So every Tuesday evening, musicians with a Piano, Bass, Drums,
          Guitar, Trumpet and Saxophone would log into a chat room and talk about their favourite jazz tracks and
          players until someone had the brilliant idea of rather than just chat, why don’t we try playing together
          online. So microphones were set up, headphones were put on and the music started flowing. Yes it was buffering
          every now and again, but it was definitely moving in the right direction.
        </p>
        <p>
          Since that digital start the band has been rehearsing face to face, playing everything from Charlie Parker and
          Miles Davis to Jaco Pastorious and Booker T & the MGs.
        </p>
      </div>
    </section>
  );
};

export default HomePageContent;
