import React from 'react';
import { useLocation } from 'react-router-dom';
import MainHeader from './MainHeader';
import classes from './Layout.module.css';
import HeroSection from './HeroSection';
import HeroHeader from './HeroHeader';

const Layout = props => {
  const pathname = useLocation().pathname;

  if (pathname === '/') {
    return (
      <>
        <header className={classes.header}>
          <HeroHeader />
          <HeroSection />
        </header>
        <main className={classes.main}>{props.children}</main>
      </>
    );
  }

  return (
    <>
      <MainHeader />
      <main className={classes.main}>{props.children}</main>
    </>
  );
};

export default Layout;
