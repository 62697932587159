import React from 'react';
import classes from './ContactPageContent.module.css';

const ContentPageContent = () => {
  return (
    <section className={classes.section}>
      <div className='container'>
        <div className={classes.cta}>
          <div className={classes.ctaTextBox}>
            <h2 className={classes.headingSecondary}>Contact us</h2>
            <p className={classes.ctaText}>
              Please use this form to submit an enquiry and we will respond as soon as possible.
            </p>
            <form className={classes.ctaForm} name='contact' method='post' data-cy='inputForm'>
              <input type='hidden' name='form-name' value='contact' />
              <div>
                <label htmlFor='name'>Your name:</label>
                <input id='name' type='text' name='name' required data-cy='inputName' />
              </div>
              <div>
                <label htmlFor='email'>Email:</label>
                <input id='email' type='email' name='email' />
              </div>
              <div>
                <label htmlFor='telephone'>Telephone:</label>
                <input id='telephone' type='tel' name='telephone' />
              </div>
              <div>
                <label htmlFor='message'>Message:</label>
                <textarea id='message' name='message' required />
              </div>
              <p>
                <button type='submit' className='btn btn--form'>
                  Send
                </button>
              </p>
            </form>
          </div>
          <div className={classes.ctaImgBox} role='img' aria-label='Hand holding smartphone'></div>
        </div>
      </div>
    </section>
  );
};

export default ContentPageContent;
