import React from 'react';
import VideoList from '../components/videos/VideoList';

const Videos = () => {
  return (
    <section className='container'>
      <h1 className='center-text heading-primary'>Videos</h1>
      <VideoList />
    </section>
  );
};

export default Videos;
