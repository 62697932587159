import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import Card from '../UI/Card';
import classes from './Track.module.css';

const Track = props => {
  return (
    <li className={classes.item}>
      <Card>
        <div className={classes.track}>
          <h2 className={classes.date}>{props.title}</h2>
          <div>
            <ReactAudioPlayer src={props.url} controls />
          </div>
        </div>
      </Card>
    </li>
  );
};

export default Track;
