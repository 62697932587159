export const tracks = [
  {
    id: 1,
    title: 'Buddy Bolden Blues',
    url: '/mp3s/001 Buddy Bolden Blues.mp3',
  },
  {
    id: 2,
    title: 'The Bare Necessities',
    url: '/mp3s/002 The Bare Necessities.mp3',
  },
];
