import React from 'react';
import TrackList from '../components/tracks/TrackList';

const Tracks = () => {
  return (
    <section className='container'>
      <h1 className='center-text heading-primary'>Tracks</h1>
      <TrackList />
    </section>
  );
};

export default Tracks;
