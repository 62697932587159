import React from 'react';
import classes from './AboutPageContent.module.css';

const AboutPageContent = () => {
  return (
    <section className='container'>
      <div className={classes.heading}>
        <div className='center-text'>
          <h1 className='heading-primary'>Hoofie and the Bad Habits</h1>
        </div>
      </div>
      <div className={classes.text}>
        <p>
          Hoofie and the Bad Habits are a group of experienced musicians based around Harpenden and Wheathampstead in
          Hertfordshire, who play a range of jazz, blues and funk music to suit all occasions.
        </p>
        <p>
          Led by Richard Hurford (Hoofie) on keyboards, we have an intstrumental line-up that includes drums, double
          bass, guitar, saxophones and trumpet.
        </p>
        <p>We play a mixture of styles and standards as well as originals. For example:</p>
        <ul className={classes.list}>
          <li>Autumn Leaves</li>
          <li>The Bare Necessities</li>
          <li>Buddy Bolden Blues</li>
          <li>Cantaloupe Island</li>
          <li>Mercy, Mercy, Mercy</li>
          <li>SKJ</li>
          <li>Song For My Father</li>
          <li>St. Thomas</li>
          <li>Work Song</li>
        </ul>
      </div>
    </section>
  );
};

export default AboutPageContent;
