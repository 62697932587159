import React from 'react';
import ReactPlayer from 'react-player';
import Card from '../UI/Card';
import classes from './Video.module.css';

const Video = props => {
  return (
    <li className={classes.item}>
      <Card>
        <div className={classes.video}>
          {/* <h2 className={classes.date}>{props.title}</h2> */}
          <div>
            <ReactPlayer url={props.url} width='100%' />
          </div>
        </div>
      </Card>
    </li>
  );
};

export default Video;
