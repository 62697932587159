import React from 'react';
import Card from '../UI/Card';
import classes from './Gig.module.css';

function Gig(props) {
  return (
    <li className={classes.item}>
      <Card>
        <div className={classes.gig}>
          <div className={classes.imageBox}>
            <img className={classes.image} src={props.image} title={props.date} alt={props.date} />
          </div>
          <div className={classes.content}>
            <h2 className={classes.date}>{props.date}</h2>
            <h3 className={classes.venue}>{props.venue}</h3>
            <p>{props.description}</p>
          </div>
        </div>
      </Card>
    </li>
  );
}

export default Gig;
