export const videos = [
  {
    id: 1,
    title: 'Lover Man',
    url: 'https://youtu.be/3Tgp_lqdLfo',
  },
  {
    id: 2,
    title: 'All Of Me',
    url: 'https://youtu.be/gs6A16ss-QM',
  },
  {
    id: 3,
    title: 'Peel Me a Grape',
    url: 'https://youtu.be/0ei-XywEgM4',
  },
  {
    id: 4,
    title: 'The Bare Necessities',
    url: 'https://youtu.be/XU3ic4bbNGA',
  },
];
