import React from 'react';
import GigList from '../components/gigs/GigList';

const Gigs = () => {
  return (
    <section className='container'>
      <h1 className='center-text heading-primary'>Upcoming Gigs</h1>
      <GigList />;
    </section>
  );
};

export default Gigs;
