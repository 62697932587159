import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { IoCloseOutline, IoMenuOutline } from 'react-icons/io5';
import classes from './HeroHeader.module.css';
import logo from '../assets/hbh-logo.png';

const HeroHeader = props => {
  const [navOpen, setNavOpen] = useState(false);

  const toggleNavOpen = () => {
    setNavOpen(!navOpen);
  };

  return (
    <header className={classes.header}>
      <Link to='/'>
        <img className={classes.logo} src={logo} alt='Hoofie and the Bad Habits logo' />
      </Link>

      <nav className={navOpen ? classes.mainNavOpen : classes.mainNav}>
        <ul className={classes.mainNavList} onClick={toggleNavOpen}>
          <li>
            <NavLink to='/about' className='heroHeader'>
              About
            </NavLink>
          </li>
          <li>
            <NavLink to='/gigs' className='heroHeader'>
              Gigs
            </NavLink>
          </li>
          <li>
            <NavLink to='/photos' className='heroHeader'>
              Photos
            </NavLink>
          </li>
          <li>
            <NavLink to='/videos' className='heroHeader'>
              Videos
            </NavLink>
          </li>
          <li>
            <NavLink to='/contact' className='mainHeaderContact'>
              Contact
            </NavLink>
          </li>
        </ul>
      </nav>

      <button className={classes.btnMobileNav} onClick={toggleNavOpen}>
        {!navOpen && <IoMenuOutline className={classes.iconMobileNav} />}
        {navOpen && <IoCloseOutline className={classes.iconMobileNav} />}
      </button>
    </header>
  );
};

export default HeroHeader;
